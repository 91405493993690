import React, { useState, useEffect } from "react";
import "../style/style.css";
import "../style/playersphere.css";

const ScheduleListView = () => {
  const [events, setEvents] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch("https://unitedcc.azurewebsites.net/api/events");
        if (!response.ok) {
          throw new Error("Failed to fetch events");
        }
        var data = await response.json();
        //const data = json.sort(function(a, b){ return new Date(a.event_date) > new Date(b.event_date); });
        setEvents(data);
        setLoading(false);
      }
      catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  const allCategories = [...new Set(events.flatMap((event) => event.categories.filter(Boolean)))];
  const filteredEvents =
    selectedCategory === "All" ? events : events.filter((event) => event.categories.includes(selectedCategory.toLowerCase()));
  const sortedEvents = filteredEvents.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));

  //const now = new Date();
  //const futureEvents = events.filter((event)=> new Date(event.event_date) > now);
  //const latestEvent = futureEvents.reduce((latest, event) => {
  //  const eventDate = new Date(event.event_date);
  //  return !latest || eventDate < new Date(latest.event_date)? event : latest; 
  //}, null);
  if (loading) {
    return <div>Loading....</div>
  }
  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className="container">
      <nav className="navbar">
        <div className="container mb-3">
          <h2 className="fw-bold">2024 T20 League</h2>
          <div className="filter-container">
            <label className="me-3 fs-5">
              <input
                className="me-1"
                type="radio"
                name="category"
                value="All"
                checked={selectedCategory === ("All")}
                onChange={() => setSelectedCategory("All")}
              />
              All
            </label>
            {allCategories.map((category) => (
              <label key={category} className="me-3 fs-5 text-capitalize">
                <input
                  className="me-1"
                  type="radio"
                  name="category"
                  value={category}
                  checked={selectedCategory === category}
                  onChange={() => setSelectedCategory(category)}
                />
                {category}
              </label>
            ))}
          </div>
        </div>
      </nav>
      <div className="container-fluid">
        <div class="table-responsive-md table-bordered">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Day</th>
                <th scope="col">Date</th>
                <th scope="col">Home vs Away</th>
                <th scope="col">Ground</th>
                <th scope="col">Team</th>
                <th scope="col">Comments</th>
              </tr>
            </thead>
            <tbody>
              {sortedEvents.map((event) => {
                const eventDate = new Date(event.event_date);
                const matchdate = eventDate.toLocaleDateString("sv-SE",{hour: "2-digit", minute: "2-digit"});
                              
                const dayName = eventDate.toLocaleDateString("default", { weekday: "long" });
                var tag = "tag";//event.categories[0] === "shaheens"? "shaheen-bg" : event.categories[0] === "superstars"? "superstar-bg" : "elite-bg";
                var bordercolor = "ucc-blue";
                var team = event.home_team.startsWith("UNITED") === true ? event.home_team : event.away_team;

                return (
                  <tr>
                    {/* <td scope="row">{event.id} {`${dayName}`}</td> */}
                    <td>{`${dayName}`}</td>
                    <td>{`${matchdate}`}</td>
                    <td>{event.title}</td>
                    <td>{event.location}</td>    
                    <td>{team}</td>  
                    <td>{event.comments}&nbsp;</td>                                     
                  </tr>                 
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ScheduleListView