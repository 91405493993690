import React, { useState } from "react";
import "../style/style.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [active, setActive] = useState(1);

  const handleItem = (index) => {
    setActive(index);
  };

  return (
    <nav className="navbar navbar-expand-sm bg-body-tertiary sticky-xxl-top border-bottom border-2 border-dark shadow-sm mb-2 bg-body-tertiary z-3">
      <div className="container navbar-inn">
        <Link className="navbar-brand" to="/">
          <img src="/united-logo.png" alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse links navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul className="nav justify-content-end">
            <li className={active === 1 ? "active nav-item" : "nav-item"} onClick={() => handleItem(1)}>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className={active === 2 ? "active nav-item" : "nav-item"} onClick={() => handleItem(2)}>
              <Link className="nav-link" to="/schedule">
                Schedule
              </Link>
            </li>
            {/* <li className={active === 3 ? "active nav-item" : "nav-item"} onClick={() => handleItem(3)}>
              <Link className="nav-link" to="/availability">
                Availability
              </Link>
            </li>
            <li className={active === 4 ? "active nav-item" : "nav-item"} onClick={() => handleItem(4)}>
              <Link className="nav-link" to="/setting">
                Setting
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;