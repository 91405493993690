import React from "react";
import "../style/style.css"

const Footer = () => {
  return (
    <div className="container-fluid footer p-4 bg-dark text-white shadow-sm">
      <div className="container">
        <div className="row">
        <div className="col-md-4 box mb-2">
        <h6 className="mb-0">United Cricket Club</h6>
        <p className="mb-0 fw-normal fs-6">Lindängens Idrottsplats</p>
        <span className="mb-0 fw-normal fs-6">Tenorgatan2, 215 74 Malmö</span>
        </div>
        <div className="col-md-4 box mb-2">
        <p className="mb-0 fw-normal fs-6"><strong className="fs-6 fw-medium">Tel. nr:</strong> 0700-503608</p>
        <p className="mb-0 fw-normal fs-6"><strong className="fs-6 fw-medium">E-post:</strong> info@unitedcc.se</p>
        <p className="mb-0 fw-normal fs-6"><strong className="fs-6 fw-medium">Webb: </strong>www.unitedcc.se </p>

        </div>
        <div className="col-md-4 box align-self-start">
        <p className="fw-normal fs-6"><strong className="fs-6 fw-medium">Bankgiro: </strong>BG 183-1197</p>
        </div>
        </div>
      </div>
    </div>  
  )
}

export default Footer