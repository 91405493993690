import React from "react"
import "../style/playersphere.css"; 

const Logo = () => {
  return (
    <div className="logo-box">
      <img src="/united-logo.png" alt="logo" />
    </div>
  )
}

export default Logo