import React from "react"
import Calendar from "./Calendar"
import NextEvent from "./NextEvent"
import SeriesSchedule from "./SeriesSchedule"
import Logo from "./Logo"

import "../style/style.css"

const Home = () => {
  return (
    <div className="container-fluid home min-vh-100">
      <div className="container">
        <div className="height-10"></div>
        <div className="row">          
          <div className="col-md-8">
          {/* <NextEvent />
          <div className="height-10"></div> */}
          <SeriesSchedule />
          </div>
          <div className="col-md-4">
            <div><Logo /> </div>
            <div className="height-10"></div>
            <div><NextEvent /> </div>
            <div className="height-10"></div>
            <div><Calendar /></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Home