import React, { useState, useEffect } from "react";
import "../style/style.css";
import "../style/playersphere.css";

const NextEvent = () => {
  const [events, setEvents] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [loading, setLoading] = useState(true);
  
  useEffect(()=>{
    const fetchEvents = async () => {
      try {
        const response = await fetch("https://unitedcc.azurewebsites.net/api/events")
        const data = await response.json();
        setEvents(data);
        setLoading(false);
      }
      catch(error){
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };
    fetchEvents();
    const intervalId = setInterval(()=> {
      setCurrentDateTime(new Date ());
    }, 1000);
    return ()=> clearInterval(intervalId)
  }, []);

  const formatDateTime = (date) =>{
    const months = ["January","February", "March", "April", "May","June", "July", "August", "Septemeber", "October", "November", "December" ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return`${month} ${day} ${year} ${hours}:${minutes}`;
  }
  const getTimeRemaining = (currentDate, targetDate) => {
    const total = targetDate - currentDate;
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes};

  }
  if (loading) {
    return <div>Loading....</div>
  }
  const upcomingEvents = events.filter(event => new Date(event.event_date) > currentDateTime);
  const sortedEvents = upcomingEvents.sort((a, b)=> new Date(a.event_date) - new Date(b.event_date));
  const nextEvent = sortedEvents.length > 0 ? sortedEvents[0]: null;
  if (!nextEvent){
    return <div>No upcoming Event!</div>
  }
  const targetDate = new Date(nextEvent.event_date);
  const {days, hours, minutes} = getTimeRemaining(currentDateTime, targetDate);
  const formattedDate = formatDateTime(targetDate); 
  return (
    <div>
      <h5 class="ucc-blue">Next Match</h5>
    <div className="card text-bg-success border border-2 border-success shadow-sm">
    <div className="card-header">{nextEvent.title}</div>
      <div className="card-body">
          <p className="mb-0 text-warning"><strong>{days} days, {hours} hours, {minutes} minutes </strong> left</p>
          <p className="mb-0"><strong>Date: </strong>{formattedDate}</p>
          <p className="mb-0"><strong>Location: </strong>{nextEvent.location}</p>
          <p className="mb-0"><strong>Series: </strong>{nextEvent.series}</p>
      </div>
    </div>
    </div>
  )
}

export default NextEvent