import React, { useState, useEffect } from "react";
import "../style/style.css";
import "../style/playersphere.css";

const SeriesSchedule = () => {
  const[events, setEvents] = useState([]);
  const[selectedCategory, setSelectedCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(()=>{
    const fetchEvents = async () => {
      try {
        const response = await fetch ("https://unitedcc.azurewebsites.net/api/events");
        if(!response.ok){
          throw new Error("Failed to fetch events");
        }
        var data = await response.json();
        data = data.filter(m => new Date(m.event_date) > new Date())
        setEvents(data);
        setLoading(false);
      }
      catch (error){
        setError(error.message);
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);
  
  const allCategories = [...new Set(events.flatMap((event)=> event.categories.filter(Boolean)))];
  const filteredEvents = 
  selectedCategory === "All"? events : events.filter((event)=> event.categories.includes(selectedCategory.toLowerCase()));
  const sortedEvents = filteredEvents.sort((a, b)=> new Date(a.event_date) - new Date(b.event_date));

  //const now = new Date();
  //const futureEvents = events.filter((event)=> new Date(event.event_date) > now);
  //const latestEvent = futureEvents.reduce((latest, event) => {
  //  const eventDate = new Date(event.event_date);
  //  return !latest || eventDate < new Date(latest.event_date)? event : latest; 
  //}, null);
  if (loading){
    return <div>Loading....</div>
  }
  if(error){
    return<div>Error: {error}</div>
  }

  return (
    <div className="container">
      <nav className="navbar">
        <div className="container mb-3">
          <h2 className="fw-bold">2024 T20 League</h2>
          <div className="filter-container">
            <label className="me-3 fs-5">
              <input
              className="me-1"
              type="radio"
              name= "category"
              value="All"
              checked={selectedCategory === ("All")}
              onChange={()=>setSelectedCategory("All")}
              />
              All
            </label>
            {allCategories.map((category)=>(
              <label key={category} className="me-3 fs-5 text-capitalize">
                <input
                className="me-1"
                type="radio"
                name="category"
                value={category}
                checked ={selectedCategory === category}
                onChange ={()=> setSelectedCategory(category)}
                /> 
                {category}
              </label>
            ))}
          </div>
        </div>
      </nav>
      <div className="events-container">
        {sortedEvents.map((event)=>{
          const eventDate = new Date(event.event_date);
          const day = eventDate.getDate();
          const month = eventDate.toLocaleString("default", {month: "short"});
          const time = eventDate.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit", });
          const dayName = eventDate.toLocaleDateString("default", {weekday: "long"});
          var tag = "tag";//event.categories[0] === "shaheens"? "shaheen-bg" : event.categories[0] === "superstars"? "superstar-bg" : "elite-bg";
          var bordercolor = "ucc-blue";
          var team = event.home_team.startsWith("UNITED") === true ? event.home_team : event.away_team;

          return(
            <div key ={event.id} className={`schedule border border-2 superstar-bg mb-2 p-3 rounded-4 event-box shadow-sm`}>
              <div className={`event-box d-flex align-items-center event-box-inner`}>
                <div className="event-date d-flex flex-column me-2 align-items-center p-1">
                  <h6 className="mb-0">{`${dayName}`}</h6>
                  <h1 className="mb-0 fw-bold">{`${day}`}</h1>
                  <h6 className="mb-0">{`${month}`}</h6>
                  <h6 className="mb-0">{`${time}`}</h6>
                </div>
              
                <div className="event-details">
                  <div className="event-title fw-bold fs-5">{event.title}</div>
                  <div className="">{event.location}</div>
                  <div className="event-comments">{event.comments}&nbsp;</div>
                  <div class="height-10" />
                  <div><span className={`${tag}`}>{team}</span></div>                  
                </div>

                {/* <div className="event-sub event-date d-flex flex-column me-2 align-items-center p-1">
                <h5 className="mb-0">{`${dayName}`}</h5>
                  <h1 className="mb-0 fw-bold">{`${day}`}</h1>
                  <h6 className="mb-0">{`${month}`}</h6>
                  <h6 className="mb-0">{`${time}`}</h6>
                </div> */}

             
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SeriesSchedule