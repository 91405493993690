import React from "react"

const Availability = () => {
  return (
    <div className="container-fluid min-vh-100">
        <div className="container">
        <h1>Availability</h1>
        </div>
    </div>
  )
}

export default Availability