import React, { useState } from 'react';
import { format, startOfWeek, addDays, addMonths, subMonths, startOfMonth, endOfMonth, isSameDay, isSameMonth, endOfWeek } from 'date-fns';
import '../style/style.css';

const Calendar = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [view, setView] = useState('month');

    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon-left" onClick={prevMonth}>
                        <i className="bi bi-arrow-left-circle"></i>
                    </div>
                </div>
                <div className="col col-center">
                    <span>{format(currentDate, dateFormat)}</span>
                </div>
                <div className="col col-end" onClick={nextMonth}>
                    <div className="icon-right">
                        <i className="bi bi-arrow-right-circle"></i>
                    </div>
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const days = [];
        const dateFormat = "E";
        const startDate = startOfWeek(currentDate, { weekStartsOn: 0 });

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 0 });
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 0 });

        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, "d");
                const cloneDay = day;
                days.push(
                    <div className={`col cell ${!isSameMonth(day, monthStart) ? "disabled" : isSameDay(day, new Date()) ? "selected" : ""}`} key={day}
                        onClick={() => onDateClick(cloneDay)}>
                        <span className="number">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>{days}</div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    }

    const renderAgenda = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const daysInMonth = [];
        for (let day = monthStart; day <= monthEnd; day = addDays(day, 1)) {
            daysInMonth.push(day);
        }
        return (
            <div className="agenda">
                {daysInMonth.map((day, index) => 
                    (                    
                        <div key={index} className="agenda-item">                        
                            <div className="agenda-date">
                                {format(day, 'MMMM d, yyyy')}
                            </div>
                            <div className="agenda-events">
                                {/* List events for the day here {day.getDate()} */}
                               No events 
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    };

    const onDateClick = day => {
        setCurrentDate(day);
    };

    const nextMonth = () => {
        setCurrentDate(addMonths(currentDate, 1));
    };

    const prevMonth = () => {
        setCurrentDate(subMonths(currentDate, 1));
    };

    const switchView = (view) => {
        setView(view);
    };

    return (
        <div className="calendar">
            {renderHeader()}
            <div className="view-switch">
                <button onClick={() => switchView('month')}>Month View</button>
                <button onClick={() => switchView('agenda')}>Agenda View</button>
            </div>
            {view === 'month' ? (
                <div>
                    {renderDays()}
                    {renderCells()}
                </div>
            ) : (
                renderAgenda()
            )}
        </div>
    );
};

export default Calendar;