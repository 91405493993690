import React from "react"
import ScheduleListView from "./ScheduleListView"

const Schedule = () => {
  return (
    <div className="container-fluid min-vh-100 d-flex flex-column align-items-center">
        <div className="container">
            <ScheduleListView />
        </div>
    </div>
  )
}

export default Schedule