import React from "react"

const Setting = () => {
  return (
    <div className="container-fluid min-vh-100">
      <div className="container">
          <h1>Settings</h1>
      </div>
    </div>
  )
}

export default Setting