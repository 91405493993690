import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Home from "./Home";
import Schedule from "./Schedule";
import Availability from "./Availability"
import Setting from "./Setting";
import Footer from "./Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../style/style.css";
import "../style/playersphere.css"


const App = () => {
  const [toggle, setToggle] = useState(true);
  const Toggle = () =>{
    setToggle(!toggle)
  }

  return (
    <Router>
    <div className="flex-grow-1 d-flex flex-column min-vh-100">
      <div className="flex-grow-1">
      {toggle && <Header/>}
      <Routes>
      <Route path="/" element={<><Home  Toggle={Toggle}/></>}></Route>
      <Route path="/schedule" element={<><Schedule /></>}></Route>
      <Route path="/availability" element={<><Availability /></>}></Route>
      <Route path="/setting" element={<><Setting /></>}></Route>
      
      </Routes>
      </div>
    <Footer />
  
    </div>
       
   
    </Router>
    
  );
};

export default App;